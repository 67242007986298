<div class="search-select">
	<div class="select-filters">
			<p-button
				*ngFor="let filter of visibleFilterOptions"
				pButton
				label="{{filter.name}}"
				icon="pi {{filter.icon}}"
				class="p-button-rounded p-button-text"
				[class.active]="isFilterActive(filter.index)"
				(click)="toggleFilter(filter.index)">
			</p-button>
	</div>

	<div *ngIf="filterOptions.length > 6" class="expand-icon" (click)="toggleFilterVisibility()">
		<p-button
			[class]="(filtersExpanded ? 'pi-chevron-up' : 'pi-chevron-down') + ' pi'"
			[pTooltip]="(filtersExpanded ? 'Weniger' : 'Mehr') + ' Anzeigen'"
      [text]="true">
		</p-button>

		<p-tag *ngIf="activeFilters.length > 0" class="filters-active-tag" styleClass="filters-active-message" value="Es sind Filter Aktiv" />
	</div>

	<div class="filtered-data" *ngIf="searchResults.length > 0">
		<h4>Suchergebnisse</h4>
		<ul>
			<li *ngFor="let searchResult of getPaginatedResults()">
				<a pRipple
					 class="data-li cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
					 [attr.href]="getRouterPath(searchResult)">
						<span class="item-name">{{ searchResult.result }}</span>
						<div class="item-information">
							<tb-items-overlay
								class="items-overlay"
								*ngIf="searchResult.items"
								[items]="searchResult.items"
							/>
							<span class="item-category">{{ getTranslatedType(searchResult.index) }}</span>
					</div>
				</a>
			</li>
		</ul>
	</div>

	<div class="pagination-controls" *ngIf="getTotalPages() > 1">
		<p-button pButton label="" icon="pi pi-chevron-left" (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1"></p-button>
		<span>Seite {{currentPage}} von {{getTotalPages()}}</span>
		<p-button pButton label="" icon="pi pi-chevron-right" (click)="changePage(currentPage + 1)" [disabled]="currentPage === getTotalPages()"></p-button>
	</div>
</div>
