.searchbar {
  position: relative;

  .p-inputgroup-addon {
    padding: 0 !important;
  }

  .search-button {
    border-radius: 0 4px 4px 0 !important;
  }

  .clear-button {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 7rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
  }

  .global-toggle {
    margin-left: 5px;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    > .pi.p-button-icon {
      color: #ffff;
    }
  }
  .p-togglebutton.p-togglebutton-checked::before{
    background: transparent;
    box-shadow: none;
  }

  .global-toggle .p-togglebutton-label {
    display: none;
  }
}
