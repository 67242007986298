<div class="searchbar">
	<p-inputgroup>
		<input
			pInputText
			type="text"
			placeholder="Suche"
			class="w-full w-30rem"
			[(ngModel)]="inputValue"
			(ngModelChange)="this.inputChanged.next($event)"
			(keydown.enter)="doSearch()"
			(keydown.shift.enter)="doGlobalSearch()"
			(focus)="onFocus()"
		/>

		<p-button
			*ngIf="inputValue"
			type="button"
			pButton
			icon="pi pi-times"
			class="clear-button"
      [text]="true"
			(click)="inputValue = ''; inputChanged.next(''); doSearch();">
		</p-button>

		<p-button
			pButton
			icon="pi pi-search"
			class="search-button"
			(click)="doSearch()">
		</p-button>

		<p-toggleButton
			styleClass="global-toggle"
			onIcon="pi pi-globe"
			offIcon="pi pi-list"
			[(ngModel)]="isGlobalSearch"
			[pTooltip]="isGlobalSearch ? 'Globale Suche' : 'Lokale Suche'"
      [disabled]="disabled"
		/>
	</p-inputgroup>

	<tb-search-overlay
		*ngIf="isGlobalSearch && selectVisible"
		[activeFilters]="activeFilters"
		[searchResults]="searchResults"
		(activeFilterChanged)="activeFilterChanged($event)"
		(onRouteChange)="onRouteChange()"
	/>
</div>
