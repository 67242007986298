import { Component, ElementRef, HostListener, Input, ViewEncapsulation } from '@angular/core'
import { LocalSearchService } from '@core/services/search.service'
import { InputTextModule } from 'primeng/inputtext'
import { PaginatorModule } from 'primeng/paginator'
import { InputGroup } from 'primeng/inputgroup'
import { InputGroupAddonModule } from 'primeng/inputgroupaddon'
import { Subject } from 'rxjs'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { SearchOverlayComponent } from '@app/components/uiux/searchbar/search-overlay/search-overlay.component'
import { NgIf } from '@angular/common'
import { SearchService } from '@core/data-services/shared/search.service'
import { SearchResult } from '@core/models/shared/search.model'
import { ToggleButtonModule } from 'primeng/togglebutton'
import { FormsModule } from '@angular/forms'

@Component({
	selector: 'tb-search',
	standalone: true,
	templateUrl: 'searchbar.component.html',
	styleUrl: './searchbar.component.scss',
	encapsulation: ViewEncapsulation.None,
  imports: [
    InputTextModule,
    PaginatorModule,
    InputGroup,
    InputGroupAddonModule,
    ButtonModule,
    TooltipModule,
    SearchOverlayComponent,
    NgIf,
    ToggleButtonModule,
    FormsModule
  ]
})
export class SearchbarComponent {

  @Input() disabled: boolean = false

	protected inputValue: string = ''
	protected inputChanged: Subject<string> = new Subject<string>()
	protected isGlobalSearch: boolean = false
	protected selectVisible: boolean = false // previously dropdownVisible
	protected searchResults: SearchResult[] = []

	protected activeFilters: string[] = []

	constructor(
		protected localSearchService: LocalSearchService,
		private eRef: ElementRef,
		private searchService: SearchService) {

		this.localSearchService.subject$.subscribe(val => this.inputValue = val)
	}

	protected doSearch(): void {
		if (!this.isGlobalSearch) this.localSearchService.search(this.inputValue.trim())
		else this.doGlobalSearch()
	}

	protected doGlobalSearch(): void {
		this.searchService.search({
			'indices': this.activeFilters,
			'searchTerm': this.inputValue
		}).subscribe(searchResults => {
			this.searchResults = searchResults
		})
	}

	protected activeFilterChanged(filter: string): void {
		const index: number = this.activeFilters.indexOf(filter)

		if (index === -1) {
			this.activeFilters.push(filter)
		} else {
			this.activeFilters.splice(index, 1)
		}
	}

	protected onRouteChange(): void {
		this.selectVisible = false
	}

	onFocus(): void {
		this.selectVisible = true
	}

	@HostListener('document:click', [ '$event' ])
	clickout(event: Event): void {
		if (!this.eRef.nativeElement.contains(event.target)) {
			this.selectVisible = false
		}
	}

}
