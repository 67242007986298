.search-select {
  position: absolute;
  background: rgba(17, 17, 17, 0.6);
  left: -50%;
  width: 1000px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  z-index: 2000;

  .select-filters {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.8rem;

    .active {
      background-color: #f79530 !important;
    }
  }

  .expand-icon {
    position: relative;
    display: flex;
    justify-content: center;

    p-button {
      padding: 5px 10px;
      cursor: pointer;

    }

    p-button-primary-background {
      border-color: transparent !important;
      background-color: transparent !important;
    }

    .filters-active-tag {
      align-self: flex-end;
      position: absolute;
      right: 0;

      .filters-active-message {
        background: #f57c00 !important;

        span {
          font-size: 0.8rem !important;
          font-weight: 400;
        }
      }
    }

  }
  .expand-icon .p-button-label {
    display: none;
  }

  .filtered-data {
    h4 {
      margin-bottom: 10px;
      color: #b5b5b5;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      .data-li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #333;

        .items-overlay {
          margin-right: 10px;
        }

        .item-name {
          font-weight: bold;
        }

        .item-category {
          font-size: 0.85em;
          color: #888;
        }
      }
    }
  }

  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .pagination-controls button {
    margin: 0 0.5rem;
  }

}
